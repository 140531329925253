<template>
    <a-modal
        :visible="visible"
        title="Unggah Salesperson"
        @cancel="handleModalCancel"
        width="70%"
        :destroy-on-close="true">

        <template #footer>
            <AButton
                @click="handleModalCancel">Batal</AButton>
            <AButton
                key="submit"
                type="primary"
                :loading="state.loading"
                @click="handleSubmitOk">Submit</AButton>
        </template>

        <AForm
            ref="formRef"
            class="myform"
            :model="state.form"
            :rules="state.rules"
            @submit.prevent="btnUploadFile()"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- upload documen -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Unggah Dokumen"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        has-feedback>
                        <a-input
                            type="file"
                            required
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            @change="onFileChange"
                        ></a-input>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row class="form-row mb-4">
                <ACol :sm="4"/>
                <a-col :sm="20">
                    <ASpace>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="state.form.loading">Upload</a-button>
                        <AButton
                            @click="btnDownloadTemplate()"
                            :loading="state.loadingTemplate">Unduh Template</AButton>
                    </ASpace>
                </a-col>
            </a-row>
        </AForm>

        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <br>

        <MdTable
            :columns="state.columns"
            :data-source="state.data"
            row-key="code"
            :row-selection="rowSelection"
            size="small"
            :loading="state.loading">
            <template #tags="{ record }">
                <a-tag :color="record.errors.length == 0 ? 'green' : 'volcano'">
                <template #icon>
                    <template v-if="record.errors.length == 0"> <CheckCircleOutlined /> Ya </template>
                    <template v-else>
                        <APopover trigger="click">
                            <template #content>
                                <AList size="small" :data-source="record.errors">
                                    <template #renderItem="{ item }">
                                        <AListItem>{{ item }}</AListItem>
                                    </template>
                                </AList>
                            </template>
                            <CloseCircleOutlined /> Tidak
                        </APopover>
                    </template>
                </template>
                </a-tag>
            </template>
        </MdTable>

         <AModal
            v-model:visible="state.result.isShow"
            title="Result"
            @cancel="handleModalCancel"
            @ok="handleModalCancel">
            <template #footer>
                <AButton key="back" type="primary" @click="handleShowCancel">OK</AButton>
            </template>
            <AAlert type="success" :message="`Sukses: ${state.result.valid}`"></AAlert>
            <AAlert type="error">
                <template #message>
                {{ `Gagal: ${state.result.failed}` }}<a-tree :tree-data="state.data" default-expand-all></a-tree> </template
            ></AAlert>
        </AModal>

    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
    computed,
} from 'vue'
import apiClient from '@/services/axios'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'
import { Modal, message } from 'ant-design-vue'
import moment from 'moment'

export default defineComponent({
    components: {
        CheckCircleOutlined,
        CloseCircleOutlined,
    },
    props: {
        visible: [Boolean],
    },
    emits: ['btnUploadFileConversionPoint', 'update:visible'],
    setup(props, { emit }) {
        const errorMessage = ref()

        const formRef = ref();

        const state = reactive({
            columns: [
                {
                    title: 'Valid?',
                    dataIndex: 'valid',
                    slots: {
                        customRender: 'tags',
                    },
                },
                {
                    title: 'Kode Toko',
                    dataIndex: 'customer',
                },
                {
                    title: 'SSM',
                    dataIndex: 'ssm_name',
                },
                {
                    title: 'ASM',
                    dataIndex: 'sm_name',
                },
                {
                    title: 'TSO',
                    dataIndex: 'am_name',
                },
                // {
                //     title: 'Action',
                //     dataIndex: 'field',
                // },
            ],
            endpoint: `/api/vendor-user/upload`,
            endpoint_template: `/sample-upload/master-update-salesperson.xlsx`,
            data: [],
            loading: false,
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            selectedRowKeys: [],
            selectedRows: [],
            result: {
                isShow: false,
                data: [],
                valid: 0,
                failed: 0,
            },
            form: {
                loading: false,
                files: null,
                file: null,
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
        }

        const onFileChange = (e) => {
            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return
            state.form.file = files[0]
        }

        const rowSelection = computed(() => {
            return {
                selectedRowKeys: state.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    state.selectedRowKeys = selectedRowKeys
                    state.selectedRows = selectedRows
                },
                getCheckboxProps: record => ({
                    disabled: record.errors.length > 0,
                }),
            }
        })

        const btnUploadFile = async () => {
            // validation form
            await formRef.value.validate()
                .catch(() => { });
            
            state.form.loading = true

            const form_data = new FormData()

            form_data.append('file', state.form.file)

            apiClient.post(state.endpoint, form_data)
                .then(({ data }) => {
                    state.data = data
                })
                .catch(async error => {
                    errorMessage.value = null

                    if (error.response && error.response.status !== 500) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }

                    if (error.response && error.response.status === 500) {
                        const { status, statusText } = error.response
                        const message = error.response.data.message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }

                    message.error('Gagal mengungah!')
                })
                .finally(() => {
                    state.form.loading = false
                })
        }

        const handleSubmitOk = async () => {
            errorMessage.value = null
            state.loading = true

            apiClient
                .post(state.endpoint, { simpan: state.selectedRows })
                .then(({ data }) => {
                    if (Array.isArray(data) && data.length === 0) {
                        message.warning('Tidak ada data!, pastikan sudah memilih datanya')
                        return
                    }

                    state.result.isShow = true

                    data.forEach((value, idx) => {
                        if ((value.success === false) || (value.errors)) {
                            state.result.failed++
                            const branch = {}
                            state.result.data.push(branch)
                            branch.title = 'Baris ' + (idx + 1)
                            branch.key = idx
                            branch.children = []
                            if (value.errors) {
                                value.errors.forEach((v, i) => {
                                    branch.children.push({
                                        title: `Kolom ${v.field} : ${v.message}`,
                                        key: `${idx}-${i}`,
                                    })
                                })
                            }
                        } else {
                            state.result.valid++
                        }
                    })
                    
                    emit('success', data)
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = error.response.data.message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.loading = false
                    state.selectedRowKeys = []
                    state.selectedRows = []
                })
        }

        const btnDownloadTemplate = async () => {
            state.loadingTemplate = true

            apiClient
                .get(state.endpoint_template, {
                    params: {
                        _export: 'xls',
                    },
                    responseType: 'blob',
                    headers: {},
                })
                .then(response => {
                    if (response === undefined) {
                        errorMessage.value = `Kode error 500, No response from server`
                        return
                    }
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `Master-Data-Toko-Salesperson_${moment().format('DDMMYY_HHmm')}.xlsx`)
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.loadingTemplate = false
                })
        }

        const handleShowCancel = () => {
            state.result.isShow = false
            handleModalCancel()
        }

        return {
            errorMessage,
            state,
            formRef,
            btnUploadFile,
            handleSubmitOk,
            handleModalCancel,
            onFileChange,
            rowSelection,
            btnDownloadTemplate,
            handleShowCancel,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
